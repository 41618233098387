import React from 'react'

const PowerIcon = ({ height = 25, top = 0, style, className }) => {
  return (
    <svg height={height} style={{ marginTop: top, ...style }} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.1652 6.07382C33.1108 5.61317 31.8827 6.09452 31.422 7.14892C30.9614 8.20333 31.4427 9.43146 32.4971 9.89211C39.2757 12.8535 43.7499 19.5579 43.7499 27.0832C43.7499 37.4381 35.3542 45.8333 24.9998 45.8333C14.644 45.8333 6.24964 37.4389 6.24964 27.0832C6.24964 19.5596 10.7223 12.8551 17.4989 9.89181C18.5532 9.43087 19.0341 8.20254 18.5731 7.14834C18.1121 6.09413 16.8838 5.61317 15.8296 6.07421C7.54936 9.69484 2.08301 17.8888 2.08301 27.0831C2.08301 39.74 12.343 49.9999 24.9999 49.9999C37.6555 49.9999 47.9168 39.7393 47.9168 27.0831C47.9168 17.8867 42.4482 9.6923 34.1652 6.07382Z" fill="black"/>
      <path d="M24.9988 22.9169C26.1494 22.9169 27.0822 21.9842 27.0822 20.8336V2.08341C27.0823 0.932722 26.1494 0 24.9988 0C23.8482 0 22.9155 0.932722 22.9155 2.08332V20.8335C22.9155 21.9841 23.8482 22.9169 24.9988 22.9169Z" fill="black"/>
    </svg>
  )
}

export default PowerIcon
