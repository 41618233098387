import React from 'react'

const MoreIcon = ({ height = 20, top = 0 }) => {
  return (
    <svg height="27" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1666 44.3333C23.8463 44.3333 25.4572 43.6661 26.6449 42.4783C27.8327 41.2906 28.4999 39.6797 28.4999 38C28.4999 36.3203 27.8327 34.7094 26.6449 33.5216C25.4572 32.3339 23.8463 31.6667 22.1666 31.6667C20.4869 31.6667 18.876 32.3339 17.6882 33.5216C16.5005 34.7094 15.8333 36.3203 15.8333 38C15.8333 39.6797 16.5005 41.2906 17.6882 42.4783C18.876 43.6661 20.4869 44.3333 22.1666 44.3333V44.3333ZM44.3333 38C44.3333 39.6797 43.666 41.2906 42.4783 42.4783C41.2905 43.6661 39.6796 44.3333 37.9999 44.3333C36.3202 44.3333 34.7093 43.6661 33.5216 42.4783C32.3338 41.2906 31.6666 39.6797 31.6666 38C31.6666 36.3203 32.3338 34.7094 33.5216 33.5216C34.7093 32.3339 36.3202 31.6667 37.9999 31.6667C39.6796 31.6667 41.2905 32.3339 42.4783 33.5216C43.666 34.7094 44.3333 36.3203 44.3333 38V38ZM53.8333 44.3333C55.513 44.3333 57.1239 43.6661 58.3116 42.4783C59.4993 41.2906 60.1666 39.6797 60.1666 38C60.1666 36.3203 59.4993 34.7094 58.3116 33.5216C57.1239 32.3339 55.513 31.6667 53.8333 31.6667C52.1535 31.6667 50.5426 32.3339 49.3549 33.5216C48.1672 34.7094 47.4999 36.3203 47.4999 38C47.4999 39.6797 48.1672 41.2906 49.3549 42.4783C50.5426 43.6661 52.1535 44.3333 53.8333 44.3333V44.3333Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M72.647 38C72.647 57.1338 57.1338 72.647 38 72.647C18.8661 72.647 3.35291 57.1338 3.35291 38C3.35291 18.8662 18.8661 3.35294 38 3.35294C57.1338 3.35294 72.647 18.8662 72.647 38ZM66.8725 38C66.8725 53.9463 53.9463 66.8725 38 66.8725C22.0537 66.8725 9.12741 53.9463 9.12741 38C9.12741 22.0537 22.0537 9.12745 38 9.12745C53.9463 9.12745 66.8725 22.0537 66.8725 38Z" fill="black"/>
    </svg>
  )
}

export default MoreIcon
