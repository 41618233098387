import React from 'react'


const MessageIcon = ({type = 'outline'}) => {
  return (
    type === 'outline' ?
      <svg width="23" height="23" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth='1'>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39 6C20.7741 6 6 20.7741 6 39V72H39C57.2259 72 72 57.2259 72 39C72 20.7741 57.2259 6 39 6ZM0 39C0 17.4603 17.4603 0 39 0C60.5397 0 78 17.4603 78 39C78 60.5397 60.5397 78 39 78H3C1.34315 78 0 76.6569 0 75V39ZM18 28.2C18 26.5431 19.3431 25.2 21 25.2H53.4C55.0569 25.2 56.4 26.5431 56.4 28.2C56.4 29.8569 55.0569 31.2 53.4 31.2H21C19.3431 31.2 18 29.8569 18 28.2ZM18 42.6C18 40.9431 19.3431 39.6 21 39.6H53.4C55.0569 39.6 56.4 40.9431 56.4 42.6C56.4 44.2569 55.0569 45.6 53.4 45.6H21C19.3431 45.6 18 44.2569 18 42.6ZM18 57C18 55.3431 19.3431 54 21 54H39C40.6569 54 42 55.3431 42 57C42 58.6569 40.6569 60 39 60H21C19.3431 60 18 58.6569 18 57Z" fill="black"/>
      </svg> :
      <svg width="23" height="23" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75 39C75 58.8828 58.8828 75 39 75H3V39C3 19.1172 19.1172 3 39 3C58.8828 3 75 19.1172 75 39Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39 6C20.7741 6 6 20.7741 6 39V72H39C57.2259 72 72 57.2259 72 39C72 20.7741 57.2259 6 39 6ZM0 39C0 17.4603 17.4603 0 39 0C60.5397 0 78 17.4603 78 39C78 60.5397 60.5397 78 39 78H3C1.34315 78 0 76.6569 0 75V39Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 28.2C18 26.5432 19.3431 25.2 21 25.2H53.4C55.0569 25.2 56.4 26.5432 56.4 28.2C56.4 29.8569 55.0569 31.2 53.4 31.2H21C19.3431 31.2 18 29.8569 18 28.2ZM18 42.6C18 40.9432 19.3431 39.6 21 39.6H53.4C55.0569 39.6 56.4 40.9432 56.4 42.6C56.4 44.2569 55.0569 45.6 53.4 45.6H21C19.3431 45.6 18 44.2569 18 42.6ZM18 57C18 55.3432 19.3431 54 21 54H39C40.6569 54 42 55.3432 42 57C42 58.6569 40.6569 60 39 60H21C19.3431 60 18 58.6569 18 57Z" fill="white"/>
      </svg>

  )
}

export default MessageIcon
