import React from 'react'

const VideoUploadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2821 0C5.97949 0 1.57949 3.31579 0 7.95789L2.70769 8.8421C3.8359 5.30526 7.33333 2.76316 11.2821 2.76316C13.5385 2.76316 15.4564 3.53684 17.0359 4.86316L14.1026 7.73684H22V0L19.0667 2.87368C16.9231 1.10526 14.2154 0 11.2821 0ZM19.7436 12.1579V18.7895C19.7436 20.0053 18.7282 21 17.4872 21H15.2308C13.9897 21 12.9744 20.0053 12.9744 18.7895V12.1579C12.9744 10.9421 13.9897 9.94737 15.2308 9.94737H17.4872C18.7282 9.94737 19.7436 10.9421 19.7436 12.1579ZM15.2308 12.1579V18.7895H17.4872V12.1579H15.2308ZM6.20513 9.94737C4.9641 9.94737 3.94872 10.9421 3.94872 12.1579V18.7895C3.94872 20.0053 4.9641 21 6.20513 21H8.46154C9.70256 21 10.7179 20.0053 10.7179 18.7895V16.5789C10.7179 15.3632 9.70256 14.3684 8.46154 14.3684H6.20513V12.1579H10.7179V9.94737H6.20513ZM6.20513 16.5789H8.46154V18.7895H6.20513V16.5789Z" fill="#e74b5d"/>
    </svg>

  )
}

export default VideoUploadIcon