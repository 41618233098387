import React from 'react'

const BurnIcon = ({ height = 23, top = 0 }) => {
  return (
    <svg height={height} style={{ marginTop: top }} viewBox="0 0 16 21" fill="none">
      <path d="M8.2894 0.984003C6.3454 2.988 5.4814 4.8 5.4814 6.84C5.4814 7.92 5.6734 8.64 6.2494 9.84C6.8374 11.064 6.8494 11.736 6.2734 12.312C5.9374 12.648 5.7814 12.72 5.3494 12.72C4.1494 12.72 3.4894 11.76 3.3574 9.876C3.2854 8.688 3.1054 8.28 2.6494 8.28C1.9174 8.28 0.861405 9.288 0.453405 10.368C-0.890595 13.956 0.861405 18.336 4.2814 20.016C5.6374 20.676 6.4774 20.88 7.9414 20.88C10.4014 20.88 12.4174 19.944 13.9774 18.084C15.6454 16.092 16.0054 13.308 14.8894 10.956C14.6374 10.428 14.1814 9.888 12.9814 8.7C10.5454 6.276 10.1374 5.52 10.1254 3.42C10.1254 2.76 10.1974 1.824 10.2814 1.356C10.4254 0.552002 10.4254 0.468004 10.2214 0.240004C10.0894 0.0960045 9.8494 3.8147e-06 9.6214 3.8147e-06C9.3094 3.8147e-06 9.0934 0.156002 8.2894 0.984003ZM8.9854 5.88C9.4174 7.056 10.0174 7.86 11.5774 9.372C12.3574 10.128 13.1374 10.968 13.3174 11.232C14.1334 12.54 14.2294 14.544 13.5334 16.02C12.6094 18.012 10.2454 19.44 7.8934 19.44C6.9694 19.44 5.4814 19.044 4.6894 18.6C3.4294 17.868 2.3734 16.572 1.8694 15.12C1.5334 14.16 1.4614 11.856 1.7494 11.172L1.9174 10.776L2.2414 11.772C2.5174 12.612 2.6614 12.84 3.1654 13.332C4.8574 14.952 7.4374 14.34 8.0734 12.168C8.3374 11.244 8.2174 10.5 7.5574 9.072C7.1254 8.16 6.9934 7.716 6.9454 7.092C6.8614 5.892 7.3174 4.572 8.2174 3.384L8.5414 2.952L8.6254 4.032C8.6734 4.68 8.8054 5.4 8.9854 5.88Z" fill="#D32F2F"/>
    </svg>
  )
}

export default BurnIcon