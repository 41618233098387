import React from 'react'

const WalletIcon = ({ size = 20, type }) => {
  return (
    type === 'outline' ?
      <svg height="23" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52 36.75C51.138 36.75 50.3114 37.0924 49.7019 37.7019C49.0924 38.3114 48.75 39.138 48.75 40C48.75 40.862 49.0924 41.6886 49.7019 42.2981C50.3114 42.9076 51.138 43.25 52 43.25H58C58.862 43.25 59.6886 42.9076 60.2981 42.2981C60.9076 41.6886 61.25 40.862 61.25 40C61.25 39.138 60.9076 38.3114 60.2981 37.7019C59.6886 37.0924 58.862 36.75 58 36.75H52ZM10 0.75C7.54675 0.75 5.19397 1.72455 3.45926 3.45926C1.72455 5.19397 0.75 7.54675 0.75 10V58C0.75 62.0446 2.35669 65.9234 5.21662 68.7834C8.07655 71.6433 11.9554 73.25 16 73.25H61C64.2489 73.25 67.3647 71.9594 69.662 69.662C71.9594 67.3647 73.25 64.2489 73.25 61V25C73.2499 22.8497 72.6839 20.7373 71.6087 18.8752C70.5577 17.0548 69.0564 15.5363 67.25 14.4647V13C67.25 9.7511 65.9594 6.63526 63.6621 4.33794C61.3647 2.04062 58.2489 0.75 55 0.75H10ZM10 7.25H55C56.525 7.25 57.9875 7.8558 59.0659 8.93414C60.0851 9.9534 60.6822 11.3159 60.7446 12.75H10C9.27065 12.75 8.57118 12.4603 8.05546 11.9445C7.53973 11.4288 7.25 10.7293 7.25 10C7.25 9.27065 7.53973 8.57118 8.05546 8.05546C8.57118 7.53973 9.27065 7.25 10 7.25ZM10 19.25H61C62.525 19.25 63.9875 19.8558 65.0659 20.9341C66.1442 22.0125 66.75 23.475 66.75 25V61C66.75 62.525 66.1442 63.9875 65.0659 65.0659C63.9875 66.1442 62.525 66.75 61 66.75H16C13.6794 66.75 11.4538 65.8281 9.81282 64.1872C8.17187 62.5462 7.25 60.3206 7.25 58V18.836C8.11806 19.105 9.04473 19.25 10 19.25Z" fill="#e61c34" strokeWidth="1"/>
      </svg> :
      <svg height="23" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0H54C57.1826 0 60.2348 1.26428 62.4853 3.51472C64.7357 5.76515 66 8.8174 66 12V13.608C67.8242 14.6612 69.339 16.176 70.3922 18.0002C71.4454 19.8243 71.9999 21.8936 72 24V60C72 63.1826 70.7357 66.2348 68.4853 68.4853C66.2348 70.7357 63.1826 72 60 72H15C11.0218 72 7.20644 70.4196 4.3934 67.6066C1.58035 64.7936 0 60.9782 0 57V9ZM6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12H60C60 10.4087 59.3679 8.88258 58.2426 7.75736C57.1174 6.63214 55.5913 6 54 6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9ZM51 36C50.2044 36 49.4413 36.3161 48.8787 36.8787C48.3161 37.4413 48 38.2044 48 39C48 39.7956 48.3161 40.5587 48.8787 41.1213C49.4413 41.6839 50.2044 42 51 42H57C57.7956 42 58.5587 41.6839 59.1213 41.1213C59.6839 40.5587 60 39.7956 60 39C60 38.2044 59.6839 37.4413 59.1213 36.8787C58.5587 36.3161 57.7956 36 57 36H51Z" fill="#e61c34"/>
      </svg>
  )
}

export default WalletIcon
