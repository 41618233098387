import React from 'react'

const SunMoonIcon = ({ height = 25, top = 0, style = 0 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M16.4838 9.99985C16.4838 6.43535 13.5838 3.53539 10.0193 3.53539C9.9151 3.53539 9.80954 3.5379 9.70551 3.54287L9.67745 2.95649C9.79076 2.95105 9.90575 2.94831 10.0193 2.94831C13.9075 2.94831 17.0708 6.1116 17.0708 9.99985C17.0708 10.131 17.0671 10.2638 17.0599 10.3945L16.4737 10.3622C16.4804 10.2421 16.4838 10.1202 16.4838 9.99985Z" fill="black"/>
        <path d="M16.4838 9.99985C16.4838 6.43535 13.5838 3.53539 10.0193 3.53539C9.9151 3.53539 9.80954 3.5379 9.70551 3.54287L9.67745 2.95649C9.79076 2.95105 9.90575 2.94831 10.0193 2.94831C13.9075 2.94831 17.0708 6.1116 17.0708 9.99985C17.0708 10.131 17.0671 10.2638 17.0599 10.3945L16.4737 10.3622C16.4804 10.2421 16.4838 10.1202 16.4838 9.99985Z" stroke="black"/>
        <path d="M11.1433 16.3671L11.2446 16.9453C11.0428 16.9807 10.8366 17.0075 10.632 17.0251L10.5817 16.4402C10.7693 16.4241 10.9582 16.3995 11.1433 16.3671Z" fill="black"/>
        <path d="M11.1433 16.3671L11.2446 16.9453C11.0428 16.9807 10.8366 17.0075 10.632 17.0251L10.5817 16.4402C10.7693 16.4241 10.9582 16.3995 11.1433 16.3671Z" stroke="black"/>
        <path d="M9.16206 2.93247C8.16507 4.05012 7.32002 5.17012 7.14072 6.71591C6.94636 8.3918 7.5408 10.0936 8.73329 11.2861C9.92417 12.477 11.629 13.076 13.3035 12.8786C14.851 12.6962 15.9678 11.8557 17.0869 10.8574C16.7793 12.0256 16.4529 13.1404 15.7314 14.1355C14.7893 15.435 13.4075 16.3921 11.857 16.8096L11.7044 16.2427C13.6282 15.7247 15.1952 14.3427 15.9661 12.5386C13.5621 14.0739 10.3292 13.7146 8.31877 11.7006C6.30785 9.68608 5.94334 6.45963 7.48123 4.05247C4.61411 5.2745 3.0042 8.48863 3.73536 11.517C4.4271 14.3822 7.0725 16.4644 10.0194 16.4644L10.0194 17.0515C6.18006 17.0515 2.96761 13.8377 2.96792 9.99997C2.96808 8.11622 3.74135 6.27923 5.08589 4.96064C6.24092 3.82793 7.63736 3.33395 9.16206 2.93247Z" fill="black"/>
        <path d="M9.16206 2.93247C8.16507 4.05012 7.32002 5.17012 7.14072 6.71591C6.94636 8.3918 7.5408 10.0936 8.73329 11.2861C9.92417 12.477 11.629 13.076 13.3035 12.8786C14.851 12.6962 15.9678 11.8557 17.0869 10.8574C16.7793 12.0256 16.4529 13.1404 15.7314 14.1355C14.7893 15.435 13.4075 16.3921 11.857 16.8096L11.7044 16.2427C13.6282 15.7247 15.1952 14.3427 15.9661 12.5386C13.5621 14.0739 10.3292 13.7146 8.31877 11.7006C6.30785 9.68608 5.94334 6.45963 7.48123 4.05247C4.61411 5.2745 3.0042 8.48863 3.73536 11.517C4.4271 14.3822 7.0725 16.4644 10.0194 16.4644L10.0194 17.0515C6.18006 17.0515 2.96761 13.8377 2.96792 9.99997C2.96808 8.11622 3.74135 6.27923 5.08589 4.96064C6.24092 3.82793 7.63736 3.33395 9.16206 2.93247Z" stroke="black"/>
        <path d="M10.3135 2.36792L10.3135 0.0195808L9.72639 0.0195808L9.72639 2.36792L10.3135 2.36792Z" fill="black"/>
        <path d="M10.3135 2.36792L10.3135 0.0195808L9.72639 0.0195808L9.72639 2.36792L10.3135 2.36792Z" stroke="black"/>
        <path d="M15.623 4.81287L17.2833 3.1526L16.8682 2.7375L15.2079 4.39777L15.623 4.81287Z" fill="black"/>
        <path d="M15.623 4.81287L17.2833 3.1526L16.8682 2.7375L15.2079 4.39777L15.623 4.81287Z" stroke="black"/>
        <path d="M4.47754 4.81396L4.89266 4.39889L3.2323 2.73871L2.81718 3.15378L4.47754 4.81396Z" fill="black"/>
        <path d="M4.47754 4.81396L4.89266 4.39889L3.2323 2.73871L2.81718 3.15378L4.47754 4.81396Z" stroke="black"/>
        <path d="M20 10.2935L20 9.70633L17.6517 9.70633L17.6517 10.2935L20 10.2935Z" fill="black"/>
        <path d="M20 10.2935L20 9.70633L17.6517 9.70633L17.6517 10.2935L20 10.2935Z" stroke="black"/>
        <path d="M2.34814 10.2935L2.34814 9.70633L-0.000183098 9.70633L-0.000183105 10.2935L2.34814 10.2935Z" fill="black"/>
        <path d="M2.34814 10.2935L2.34814 9.70633L-0.000183098 9.70633L-0.000183105 10.2935L2.34814 10.2935Z" stroke="black"/>
        <path d="M2.95508 17.6124L2.95508 18.1995L2.36799 18.1995L2.36799 18.7866L1.78091 18.7866L1.78091 18.1995L1.19383 18.1995L1.19383 17.6124L1.78091 17.6124L1.78091 17.0254L2.36799 17.0254L2.36799 17.6124L2.95508 17.6124Z" fill="black"/>
        <path d="M2.95508 17.6124L2.95508 18.1995L2.36799 18.1995L2.36799 18.7866L1.78091 18.7866L1.78091 18.1995L1.19383 18.1995L1.19383 17.6124L1.78091 17.6124L1.78091 17.0254L2.36799 17.0254L2.36799 17.6124L2.95508 17.6124Z" stroke="black"/>
        <path d="M17.0649 16.4383L17.0649 15.8512L17.652 15.8512L17.652 15.2641L18.2391 15.2641L18.2391 15.8512L18.8262 15.8512L18.8262 16.4383L18.2391 16.4383L18.2391 17.0254L17.652 17.0254L17.652 16.4383L17.0649 16.4383Z" fill="black"/>
        <path d="M17.0649 16.4383L17.0649 15.8512L17.652 15.8512L17.652 15.2641L18.2391 15.2641L18.2391 15.8512L18.8262 15.8512L18.8262 16.4383L18.2391 16.4383L18.2391 17.0254L17.652 17.0254L17.652 16.4383L17.0649 16.4383Z" stroke="black"/>
        <path d="M9.72576 18.2191L10.3128 18.2191L10.3128 18.8062L10.8999 18.8062L10.8999 19.3933L10.3128 19.3933L10.3128 19.9803L9.72576 19.9803L9.72576 19.3933L9.13869 19.3933L9.13869 18.8062L9.72576 18.8062L9.72576 18.2191Z" fill="black"/>
        <path d="M9.72576 18.2191L10.3128 18.2191L10.3128 18.8062L10.8999 18.8062L10.8999 19.3933L10.3128 19.3933L10.3128 19.9803L9.72576 19.9803L9.72576 19.3933L9.13869 19.3933L9.13869 18.8062L9.72576 18.8062L9.72576 18.2191Z" stroke="black"/>
        <path d="M4.14844 17.0905L4.14844 16.5034L3.56135 16.5034L3.56135 17.0905L4.14844 17.0905Z" fill="black"/>
        <path d="M4.14844 17.0905L4.14844 16.5034L3.56135 16.5034L3.56135 17.0905L4.14844 17.0905Z" stroke="black"/>
        <path d="M16.4619 18.7866L16.4619 18.1995L15.8748 18.1995L15.8748 18.7866L16.4619 18.7866Z" fill="black"/>
        <path d="M16.4619 18.7866L16.4619 18.1995L15.8748 18.1995L15.8748 18.7866L16.4619 18.7866Z" stroke="black"/>
      </g>
    </svg>
  )
}

export default SunMoonIcon
