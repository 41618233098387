import React from 'react'


const PocketIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 83 76" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth='2'>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.644 6.30539C9.41233 6.30539 8.23111 6.79467 7.3602 7.66559C6.48928 8.5365 6 9.71772 6 10.9494V33.8814C6 43.2223 9.71067 52.1807 16.3157 58.7857C19.5862 62.0562 23.4688 64.6505 27.7419 66.4204C32.015 68.1904 36.5948 69.1014 41.22 69.1014C50.5609 69.1014 59.5193 65.3907 66.1243 58.7857C72.7293 52.1807 76.44 43.2223 76.44 33.8814V10.9494C76.44 9.71772 75.9507 8.5365 75.0798 7.66559C74.2089 6.79467 73.0277 6.30539 71.796 6.30539H10.644ZM3.11756 3.42294C5.11369 1.42681 7.82104 0.305389 10.644 0.305389H71.796C74.619 0.305389 77.3263 1.42681 79.3224 3.42294C81.3186 5.41908 82.44 8.12642 82.44 10.9494V33.8814C82.44 44.8136 78.0972 55.2981 70.3669 63.0283C62.6367 70.7586 52.1522 75.1014 41.22 75.1014C35.8069 75.1014 30.4468 74.0352 25.4458 71.9637C20.4447 69.8922 15.9007 66.856 12.0731 63.0283C4.34281 55.2981 0 44.8136 0 33.8814V10.9494C0 8.12642 1.12142 5.41908 3.11756 3.42294Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8107 27.9381C24.9823 26.7665 26.8818 26.7665 28.0533 27.9381L41.22 41.1047L54.3867 27.9381C55.5583 26.7665 57.4578 26.7665 58.6293 27.9381C59.8009 29.1096 59.8009 31.0091 58.6293 32.1807L43.3413 47.4687C42.1698 48.6403 40.2703 48.6403 39.0987 47.4687L23.8107 32.1807C22.6391 31.0091 22.6391 29.1096 23.8107 27.9381Z" fill="black"/>
    </svg>
  )
}

export default PocketIcon
