import React from 'react'

const BuzzIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <g clipPath="url(#clip0)">
        <path d="M21.1442 14.1173C20.8696 13.8427 20.4244 13.8427 20.1498 14.1173C19.8752 14.3918 19.8752 14.8371 20.1498 15.1116L21.5682 16.53C21.7055 16.6673 21.8854 16.736 22.0653 16.736C22.2453 16.736 22.4253 16.6673 22.5626 16.53C22.8371 16.2555 22.8371 15.8103 22.5626 15.5357L21.1442 14.1173Z" fill="white"/>
        <path d="M20.647 8.29849C20.8269 8.29849 21.0069 8.22981 21.1442 8.09257L22.5626 6.67418C22.8371 6.39963 22.8371 5.95441 22.5626 5.67982C22.288 5.40523 21.8428 5.40523 21.5682 5.67982L20.1498 7.09821C19.8752 7.37275 19.8752 7.81797 20.1498 8.09257C20.2871 8.22986 20.467 8.29849 20.647 8.29849V8.29849Z" fill="white"/>
        <path d="M23.297 10.4019H21.291C20.9027 10.4019 20.5879 10.7167 20.5879 11.105C20.5879 11.4933 20.9027 11.8081 21.291 11.8081H23.297C23.6853 11.8081 24.0001 11.4933 24.0001 11.105C24.0001 10.7167 23.6853 10.4019 23.297 10.4019Z" fill="white"/>
        <path d="M18.7147 1.10554C18.4927 0.981181 18.2208 0.986525 18.0038 1.11937L9.95142 6.05085H6.37669C5.61323 6.01049 4.88695 6.27656 4.32942 6.80137C4.12191 6.99665 3.94697 7.22207 3.80756 7.4677H2.03845C0.914437 7.4677 0 8.38213 0 9.50615V12.7036C0 13.8276 0.914437 14.742 2.03845 14.742H3.80405C4.08717 15.2482 4.51458 15.6678 5.03419 15.9455L2.58445 20.1886C2.49047 20.3514 2.46558 20.545 2.51531 20.7263C2.56509 20.9076 2.68537 21.0614 2.8493 21.1534L5.95158 22.8938C6.06033 22.9548 6.17845 22.9838 6.29498 22.9838C6.53812 22.9838 6.77442 22.8575 6.9045 22.6321L10.4026 16.5732L18.0118 21.0951C18.1224 21.1609 18.2467 21.1938 18.371 21.1938C18.4909 21.1938 18.6109 21.1632 18.7187 21.1018C18.9384 20.9768 19.0741 20.7434 19.0741 20.4907V1.71899C19.0741 1.46456 18.9367 1.22995 18.7147 1.10554V1.10554ZM2.03845 13.3358C1.68984 13.3358 1.40625 13.0522 1.40625 12.7036V9.50615C1.40625 9.15754 1.68984 8.87395 2.03845 8.87395H3.42848C3.42839 8.88445 3.42769 8.8949 3.42769 8.9054V13.3043C3.42769 13.3149 3.42834 13.3253 3.42848 13.3358H2.03845ZM4.83394 8.9054C4.83394 8.49374 5.00133 8.10009 5.29317 7.8254C5.57095 7.56398 5.93339 7.43249 6.31425 7.45584C6.32845 7.45668 9.44658 7.4571 9.44658 7.4571V14.9018H6.37992C5.51137 14.8394 4.83394 14.1413 4.83394 13.3043V8.9054ZM6.03394 21.3276L4.15819 20.2753L6.4072 16.3798C6.42075 16.3564 6.43242 16.3323 6.44297 16.3081H8.93189L6.03394 21.3276ZM17.6679 19.2549L10.8528 15.2049V7.14787L17.6679 2.97412V19.2549Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default BuzzIcon
