import React from 'react'


const MoonIcon = ({type = 'outline'}) => {
  return (
    <svg width="23" height="23" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.5429 3.03467H45.3137C39.5252 8.2074 35.8045 15.1673 34.7983 22.7046C33.7922 30.2419 35.5642 37.88 39.8063 44.2912C44.0485 50.7023 50.4925 55.4812 58.0184 57.797C65.5442 60.1129 73.6759 59.8193 81 56.9673C78.1826 63.4868 73.5779 69.1485 67.677 73.3485C61.7762 77.5484 54.8005 80.1292 47.4941 80.8153C40.1877 81.5015 32.8246 80.2674 26.1903 77.2447C19.5561 74.222 13.8994 69.524 9.82374 63.6519C5.74809 57.7798 3.40633 50.9539 3.04827 43.9022C2.69021 36.8505 4.32927 29.8376 7.79062 23.6116C11.252 17.3855 16.4058 12.1798 22.7022 8.54984C28.9987 4.91985 36.2017 3.0017 43.5429 3V3.03467Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default MoonIcon
