import React from 'react'

const NotificationsIcon = ({ height = 20, top = 0, type }) => {
  return (
    type === 'outline' ?
      <svg width="25" viewBox="0 0 75 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.0421 52.6833C71.6775 52.6833 63.4927 51.2243 63.4927 27.3548C63.4927 10.4961 53.6872 0 37.4797 0C21.2723 0 11.4668 10.4961 11.4668 27.3548C11.4668 51.6701 2.99838 52.6833 3.0389 52.6833C2.23293 52.6833 1.45998 53.0035 0.890072 53.5735C0.320168 54.1435 0 54.9166 0 55.7227C0 56.5288 0.320168 57.3019 0.890072 57.8719C1.45998 58.4419 2.23293 58.7621 3.0389 58.7621H22.6094C23.2858 62.2096 25.1387 65.315 27.8511 67.5472C30.5636 69.7795 33.9671 71 37.4797 71C40.9924 71 44.3959 69.7795 47.1084 67.5472C49.8208 65.315 51.6737 62.2096 52.3501 58.7621H71.9611C72.7671 58.7621 73.54 58.4419 74.1099 57.8719C74.6798 57.3019 75 56.5288 75 55.7227C75 54.9166 74.6798 54.1435 74.1099 53.5735C73.54 53.0035 72.7671 52.6833 71.9611 52.6833H72.0421ZM37.4797 64.841C35.6009 64.8374 33.7694 64.2507 32.2381 63.1619C30.7067 62.0731 29.551 60.5358 28.9303 58.7621H46.0292C45.4085 60.5358 44.2527 62.0731 42.7214 63.1619C41.19 64.2507 39.3586 64.8374 37.4797 64.841V64.841ZM12.1961 52.6833C15.1135 48.266 17.5446 40.5256 17.5446 27.3548C17.5446 14.184 24.8379 6.07884 37.4797 6.07884C50.1216 6.07884 57.4149 13.8192 57.4149 27.3548C57.4149 40.8903 59.846 48.266 62.7634 52.6833H12.1961Z" fill="black"/>
      </svg> :
      <svg height="25" viewBox="0 0 75 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.0421 52.6833C71.6775 52.6833 63.4927 51.2243 63.4927 27.3548C63.4927 10.4961 53.6872 0 37.4797 0C21.2723 0 11.4668 10.4961 11.4668 27.3548C11.4668 51.6701 2.99838 52.6833 3.0389 52.6833C2.23293 52.6833 1.45998 53.0035 0.890072 53.5735C0.320168 54.1435 0 54.9166 0 55.7227C0 56.5288 0.320168 57.3019 0.890072 57.8719C1.45998 58.4419 2.23293 58.7621 3.0389 58.7621H22.6094C23.2858 62.2096 25.1387 65.315 27.8511 67.5472C30.5636 69.7795 33.9671 71 37.4797 71C40.9924 71 44.3959 69.7795 47.1084 67.5472C49.8208 65.315 51.6737 62.2096 52.3501 58.7621H71.9611C72.7671 58.7621 73.54 58.4419 74.1099 57.8719C74.6798 57.3019 75 56.5288 75 55.7227C75 54.9166 74.6798 54.1435 74.1099 53.5735C73.54 53.0035 72.7671 52.6833 71.9611 52.6833H72.0421ZM37.4797 64.841C35.6009 64.8374 33.7694 64.2507 32.2381 63.1619C30.7067 62.0731 29.551 60.5358 28.9303 58.7621H46.0292C45.4085 60.5358 44.2527 62.0731 42.7214 63.1619C41.19 64.2507 39.3586 64.8374 37.4797 64.841Z" fill="black"/>
      </svg>       
  )
}

export default NotificationsIcon
