import React from 'react'

const BrandIconDark = ({ height = 30, top = 0, style, className }) => {
  return (
    <svg id="a" height={height} style={{ marginTop: top, ...style }} className={className} version="1.1" viewBox="0 0 1074.9 396.03" xmlns="http://www.w3.org/2000/svg">
      <path className="b" fill="#e91c33" d="m0 157.61h95.35c18.79 0 33.98 2.88 45.55 8.64s21.13 14.02 28.69 24.79 13.03 23.29 16.43 37.58 5.1 29.42 5.1 45.42c0 25.05-2.53 44.48-7.58 58.29s-12.07 25.37-21.04 34.7-18.61 15.54-28.9 18.63c-14.08 4.26-26.83 6.4-38.25 6.4h-95.35v-234.43zm64.18 53.09v128.09h15.73c13.41 0 22.95-1.68 28.62-5.04s10.11-9.22 13.32-17.59 4.82-21.93 4.82-40.7c0-24.84-3.59-41.84-10.77-51.01s-19.08-13.75-35.7-13.75h-16.01z"/>
      <path className="c" fill="#fff" d="m224.99 157.61h120.14c20.02 0 35.4 5.6 46.12 16.79s16.08 25.05 16.08 41.58c0 13.86-3.83 25.75-11.48 35.66-5.1 6.61-12.56 11.83-22.39 15.67 14.92 4.05 25.9 11.01 32.94 20.87s10.56 22.26 10.56 37.18c0 12.15-2.5 23.08-7.51 32.78s-11.85 17.38-20.54 23.03c-5.38 3.52-13.51 6.08-24.37 7.68-14.45 2.13-24.04 3.2-28.76 3.2h-110.79v-234.43zm64.75 91.95h27.91c10.01 0 16.98-1.94 20.9-5.84 3.92-3.89 5.88-9.52 5.88-16.87 0-6.82-1.96-12.15-5.88-15.99s-10.75-5.76-20.47-5.76h-28.34zm0 92.11h32.73c11.05 0 18.84-2.21 23.38-6.64 4.53-4.42 6.8-10.37 6.8-17.83 0-6.93-2.24-12.5-6.73-16.71s-12.35-6.32-23.59-6.32h-32.59v47.49z"/>
      <path className="c" fill="#fff" d="m679.49 157.61h181.63v46.86l-116.59 137.21h120.84v50.37h-197.07v-48.61l115.37-135.77h-104.18v-50.05z"/>
      <path className="c" fill="#fff" d="m889.03 157.61h181.63v46.86l-116.59 137.21h120.84v50.37h-197.07v-48.61l115.37-135.77h-104.18v-50.05z"/>
      <g transform="translate(-207.34,-228.04)">
        <path className="b" fill="#e91c33" d="m554.51 321.24c-0.24-0.51 0-0.71 0.61-0.84 5.55-1.18 11.1-2.4 16.64-3.61 1.6-0.35 3.2-0.69 4.79-1.05 0.26-0.06 0.48-0.03 0.71 0.1 2.26 1.26 4.53 2.51 6.8 3.75 0.34 0.18 0.38 0.33 0.19 0.67-1.13 2-2.24 4-3.33 6.03-0.23 0.44-0.42 0.38-0.77 0.19-1.3-0.74-2.62-1.45-3.93-2.19-0.31-0.18-0.6-0.25-0.97-0.17-3.95 0.87-7.9 1.72-11.85 2.58-2.29 0.5-4.57 0.99-6.85 1.5-0.39 0.09-0.56 0.03-0.65-0.4-0.44-2.16-0.92-4.31-1.41-6.55z"/>
        <path className="b" fill="#e91c33" d="m575.31 300.13c0.06-0.56 0.36-0.61 0.96-0.4 5.35 1.89 10.72 3.74 16.08 5.6 1.54 0.54 3.09 1.08 4.63 1.61 0.25 0.09 0.42 0.23 0.55 0.46 1.27 2.25 2.55 4.51 3.85 6.75 0.19 0.33 0.15 0.48-0.19 0.67-2 1.12-4 2.24-5.98 3.41-0.43 0.25-0.56 0.11-0.75-0.24-0.72-1.31-1.48-2.61-2.21-3.92-0.17-0.31-0.38-0.53-0.73-0.65-3.82-1.32-7.64-2.65-11.45-3.98-2.21-0.77-4.42-1.54-6.63-2.3-0.38-0.13-0.49-0.27-0.34-0.68 0.75-2.07 1.46-4.16 2.22-6.32z"/>
        <path className="b" fill="#e91c33" d="m659.56 377.27c27.52 0 49.91 22.39 49.91 49.91 0 5.24-0.81 10.36-2.36 15.22l-62.66-62.8c4.83-1.53 9.92-2.32 15.11-2.32m0-22.12c-19.58 0-37.33 7.82-50.31 20.49l101.74 101.97c12.74-12.99 20.6-30.79 20.6-50.43 0-39.78-32.25-72.03-72.03-72.03z"/>
        <path className="b" fill="#e91c33" d="m733.86 263.24c1.56 4.87 2.36 10 2.36 15.24 0 27.52-22.39 49.91-49.91 49.91-5.21 0-10.3-0.8-15.14-2.33l62.68-62.82m3.87-35.2-101.74 101.97c12.98 12.69 30.74 20.51 50.33 20.51 39.78 0 72.03-32.25 72.03-72.03 0-19.65-7.87-37.45-20.62-50.44z"/>
        <rect className="b" fill="#e91c33" transform="rotate(-45.06 673.18 391.89)" x="644.58" y="386.11" width="57.08" height="11.53"/>
        <rect className="b" fill="#e91c33" transform="rotate(-45.06 693.71 412.08)" x="665.11" y="406.3" width="57.08" height="11.53"/>
        <path className="b" fill="#e91c33" d="m588.75 355.81c0.83 0.97 1.74 1.87 2.7 2.71l8.13 8.14 37.01-37.09-9.18-9.2-1.57-1.57-0.05 0.05c-4.6-3.99-10.6-6.41-17.17-6.41-14.49 0-26.24 11.75-26.24 26.24 0 6.55 2.4 12.53 6.37 17.12z"/>
      </g>
      <path className="c" fill="#fff" d="m578.19 157.61v143c0 13-3.14 23.04-9.42 30.12s-14.95 10.62-26 10.62-19.86-3.59-26.14-10.79c-6.28-7.19-9.42-17.18-9.42-29.96v-32.67l-64.04-63.02v92.36c0 11.51 1.98 24.72 5.95 39.63 2.46 9.27 7.01 18.27 13.67 27.01s14 15.47 22.03 20.21 18.01 7.91 29.97 9.51c11.95 1.6 22.97 2.4 33.08 2.4 17.47 0 32.44-2.61 44.91-7.83 8.97-3.73 17.54-10.2 25.71-19.41 8.17-9.22 14.17-19.98 17.99-32.28s5.74-25.38 5.74-39.23v-139.67h-64.04z"/>
    </svg>
  )
}

export default BrandIconDark
