import React from 'react'

const AddImageIcon = ({ size = 25 }) => {
  return (
    <svg height={size} width={size} viewBox="0 -18 512 512" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m512 60.054688v252.226562h-40.035156v-252.226562c0-11.039063-8.980469-20.019532-20.019532-20.019532h-391.890624c-11.039063 0-20.019532 8.980469-20.019532 20.019532v151.484374l82.820313-121.042968 66.429687 83.035156 30.101563-43.269531 128.464843 167.003906v15.015625h-38.960937l-87.878906-114.246094-112.496094 161.710938-32.867187-22.863282 89.789062-129.070312-40.070312-50.085938-85.332032 124.714844v97.902344c0 11.035156 8.980469 20.015625 20.019532 20.015625h204.722656v40.039063h-204.722656c-33.113282 0-60.054688-26.941407-60.054688-60.054688v-320.289062c0-33.113282 26.941406-60.054688 60.054688-60.054688h391.890624c33.113282 0 60.054688 26.9375 60.054688 60.054688zm-134.121094 132.117187c-33.113281 0-60.054687-26.941406-60.054687-60.054687 0-33.113282 26.941406-60.054688 60.054687-60.054688 33.113282 0 60.054688 26.941406 60.054688 60.054688 0 33.113281-26.941406 60.054687-60.054688 60.054687zm20.019532-60.054687c0-11.039063-8.980469-20.015626-20.019532-20.015626-11.039062 0-20.019531 8.976563-20.019531 20.015626 0 11.039062 8.980469 20.019531 20.019531 20.019531 11.039063 0 20.019532-8.980469 20.019532-20.019531zm30.027343 136.125h-40.039062v84.074218h-84.074219v40.035156h84.074219v84.078126h40.039062v-84.078126h84.074219v-40.035156h-84.074219zm0 0"/>
      </g>
    </svg>
  )
}

export default AddImageIcon
