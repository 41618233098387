import React from 'react'

const LatestIcon = ({ height = 24, top = 0, type }) => {
  return (
    type === 'outline' ?
      <svg height="25" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.0264 42.579L35.0263 42.5789C34.8241 42.4666 34.6595 42.3061 34.5464 42.1166C34.4335 41.9274 34.3751 41.7148 34.375 41.5C34.375 41.4999 34.375 41.4998 34.375 41.4997L34.375 16.75C34.375 16.4275 34.5064 16.1124 34.7497 15.8757C34.9938 15.6382 35.3307 15.5 35.6875 15.5C36.0443 15.5 36.3812 15.6382 36.6253 15.8757C36.8686 16.1124 37 16.4275 37 16.75V40.195V40.7832L37.5141 41.069L52.5244 49.4145C52.8148 49.5879 53.0176 49.8592 53.0995 50.1655C53.1819 50.4741 53.1379 50.8028 52.973 51.0835C52.8075 51.3652 52.5305 51.5803 52.1947 51.6736C51.8616 51.7661 51.5052 51.7289 51.2023 51.5725L35.0264 42.579Z" fill="#e61c34"/>
        <path d="M38 73.5C47.9413 73.5 57.478 69.6577 64.5116 62.8142C71.5457 55.9703 75.5 46.6849 75.5 37C75.5 27.3151 71.5457 18.0297 64.5116 11.1858C57.478 4.34229 47.9413 0.5 38 0.5C28.0587 0.5 18.522 4.34229 11.4884 11.1858C4.45431 18.0297 0.5 27.3151 0.5 37C0.5 46.6849 4.45431 55.9703 11.4884 62.8142C18.522 69.6577 28.0587 73.5 38 73.5ZM69.875 37C69.875 45.2172 66.5202 53.1007 60.5439 58.9155C54.5672 64.7307 46.4581 68 38 68C29.5419 68 21.4328 64.7307 15.4561 58.9155C9.47982 53.1007 6.125 45.2172 6.125 37C6.125 28.7828 9.47982 20.8992 15.4561 15.0845C21.4328 9.2693 29.5419 6 38 6C46.4581 6 54.5672 9.2693 60.5439 15.0845C66.5202 20.8992 69.875 28.7828 69.875 37Z" fill="#e61c34" strokeWidth="0"/>
      </svg> :
      <svg height="25" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74 37C74 46.813 70.1018 56.2241 63.163 63.163C56.2241 70.1018 46.813 74 37 74C27.187 74 17.7759 70.1018 10.837 63.163C3.8982 56.2241 0 46.813 0 37C0 27.187 3.8982 17.7759 10.837 10.837C17.7759 3.8982 27.187 0 37 0C46.813 0 56.2241 3.8982 63.163 10.837C70.1018 17.7759 74 27.187 74 37ZM37 16.1875C37 15.5742 36.7564 14.986 36.3227 14.5523C35.889 14.1186 35.3008 13.875 34.6875 13.875C34.0742 13.875 33.486 14.1186 33.0523 14.5523C32.6186 14.986 32.375 15.5742 32.375 16.1875V41.625C32.3751 42.0326 32.483 42.4329 32.6877 42.7854C32.8923 43.1379 33.1866 43.4301 33.5405 43.6322L49.728 52.8822C50.2592 53.1693 50.8817 53.2373 51.4623 53.0715C52.0429 52.9058 52.5357 52.5195 52.8353 51.9952C53.1349 51.4709 53.2175 50.8502 53.0655 50.2658C52.9136 49.6814 52.539 49.1797 52.022 48.8678L37 40.2837V16.1875Z" fill="#e61c34"/>
      </svg>    
  )
}

export default LatestIcon