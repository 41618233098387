import React from 'react'

const SearchBarIcon = ({ height = 20, top = 0, style = 0, type = 'outline' }) => {
  return (
    <svg height="18" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8354 2.56918C24.9303 0.873008 29.3192 0 33.7515 0C38.1838 0 42.5727 0.873008 46.6677 2.56918C50.7626 4.26535 54.4833 6.75147 57.6174 9.88559C60.7516 13.0197 63.2377 16.7404 64.9339 20.8354C66.63 24.9303 67.503 29.3192 67.503 33.7515C67.503 38.1838 66.63 42.5727 64.9339 46.6677C63.7503 49.525 62.1822 52.2001 60.2777 54.6208L74.8299 69.1731C76.392 70.7352 76.392 73.2678 74.8299 74.8299C73.2678 76.392 70.7352 76.392 69.1731 74.8299L54.6209 60.2778C48.7022 64.9345 41.3593 67.503 33.7515 67.503C24.8001 67.503 16.2152 63.9471 9.88559 57.6174C3.55595 51.2878 0 42.703 0 33.7515C0 24.8001 3.55595 16.2152 9.88559 9.88559C13.0197 6.75147 16.7404 4.26535 20.8354 2.56918ZM33.7515 8C30.3698 8 27.0212 8.66608 23.8968 9.96022C20.7725 11.2544 17.9337 13.1512 15.5424 15.5424C10.7131 20.3718 8 26.9218 8 33.7515C8 40.5812 10.7131 47.1312 15.5424 51.9606C20.3718 56.7899 26.9218 59.503 33.7515 59.503C40.5812 59.503 47.1312 56.7899 51.9606 51.9606C54.3518 49.5693 56.2487 46.7305 57.5428 43.6062C58.837 40.4819 59.503 37.1333 59.503 33.7515C59.503 30.3698 58.837 27.0212 57.5428 23.8968C56.2487 20.7725 54.3518 17.9337 51.9606 15.5424C49.5693 13.1512 46.7305 11.2544 43.6062 9.96022C40.4819 8.66608 37.1333 8 33.7515 8Z" fill="black"/>
    </svg>
  )
}

export default SearchBarIcon