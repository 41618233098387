import React from 'react'

const LogoutIcon = ({ height = 20, top = 0, style = {}}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <path d="M49.408,83.895c-17.974,0-32.596-14.623-32.596-32.598c0-10.55,5.159-20.498,13.801-26.61l3.273,4.629 c-7.142,5.051-11.406,13.269-11.406,21.981c0,14.849,12.08,26.928,26.927,26.928c14.847,0,26.926-12.079,26.926-26.928 c0-8.712-4.263-16.929-11.404-21.98l3.273-4.629c8.642,6.113,13.801,16.06,13.801,26.609C82.004,69.271,67.382,83.895,49.408,83.895 z"/>
    </svg>
  )
}

export default LogoutIcon
