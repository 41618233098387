import React from 'react'

const EmojiIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 20.625C8.44729 20.625 5.99913 19.6109 4.1941 17.8059C2.38906 16.0009 1.375 13.5527 1.375 11C1.375 8.44729 2.38906 5.99913 4.1941 4.1941C5.99913 2.38906 8.44729 1.375 11 1.375C13.5527 1.375 16.0009 2.38906 17.8059 4.1941C19.6109 5.99913 20.625 8.44729 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625ZM11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22V22Z" fill="#e74b5d"/>
      <path d="M16.9551 13.0625C17.0758 13.2715 17.1393 13.5086 17.1393 13.75C17.1393 13.9914 17.0758 14.2285 16.9551 14.4375C16.3518 15.483 15.4838 16.3511 14.4384 16.9546C13.393 17.558 12.2071 17.8755 11 17.875C9.79315 17.8752 8.60753 17.5577 7.56238 16.9542C6.51723 16.3508 5.64942 15.4828 5.04625 14.4375C4.92562 14.2286 4.86209 13.9916 4.86203 13.7504C4.86197 13.5091 4.92538 13.2721 5.04589 13.0631C5.16641 12.8541 5.33978 12.6805 5.54861 12.5597C5.75744 12.439 5.99438 12.3752 6.23562 12.375H15.7644C16.0057 12.375 16.2428 12.4385 16.4519 12.5592C16.6609 12.6799 16.8344 12.8535 16.9551 13.0625ZM9.625 8.9375C9.625 10.076 9.009 8.9375 8.25 8.9375C7.491 8.9375 6.875 10.076 6.875 8.9375C6.875 7.799 7.491 6.875 8.25 6.875C9.009 6.875 9.625 7.799 9.625 8.9375ZM15.125 8.9375C15.125 10.076 14.509 8.9375 13.75 8.9375C12.991 8.9375 12.375 10.076 12.375 8.9375C12.375 7.799 12.991 6.875 13.75 6.875C14.509 6.875 15.125 7.799 15.125 8.9375Z" fill="#e74b5d"/>
    </svg>
  )
}

export default EmojiIcon
