import React from 'react'

const AccordionArrowDownIcon = ({ size = 25 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 79 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M43.768 44.2438C42.6553 45.3683 41.1463 46 39.5729 46C37.9995 46 36.4906 45.3683 35.3779 44.2438L1.81146 10.3125C1.24474 9.75918 0.792705 9.09732 0.481731 8.36553C0.170756 7.63374 0.00707032 6.84666 0.000224031 6.05024C-0.00662226 5.25381 0.14351 4.46398 0.441856 3.72684C0.740203 2.98969 1.18079 2.31999 1.73791 1.75681C2.29504 1.19363 2.95753 0.748248 3.68675 0.446658C4.41597 0.145067 5.1973 -0.00669425 5.98516 0.000226471C6.77302 0.0071472 7.55163 0.172613 8.27555 0.486968C8.99948 0.801323 9.65422 1.25827 10.2016 1.83115L39.5729 31.5218L68.9443 1.83115C70.0633 0.738547 71.5622 0.133971 73.118 0.147637C74.6737 0.161304 76.1619 0.792118 77.2621 1.90422C78.3622 3.01632 78.9863 4.52072 78.9998 6.09341C79.0133 7.66609 78.4152 9.18123 77.3344 10.3125L43.768 44.2438Z" fill="black"/>
    </svg>

  )
}

export default AccordionArrowDownIcon
